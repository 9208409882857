<template>
  <div id="proveedor">

    <vx-card class="mb-6" >
      <div class="vx-row">
        <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">tecnologías</span>.</h1>
            <p class="text-subtitle mt-4">
              En este apartado podrás solicitar la acreditación para todas aquellas tecnologías que comercializas. Una vez que completes la información
              y solicites la acreditación, RedGirasol revisará la información y te podrá acreditar la tecnología. Una vez acreditado podrás crear proyectos con 
              esta tecnología.
            </p>
        </div>
      </div>
    </vx-card>
    
    <accreditations-control />

  </div>
</template>

<script>
import AccreditationsControl from '@components/supplier/profile/AccreditationsControl.vue'
export default {
  components: {
    AccreditationsControl
  },
  data(){
    return {      
    }
  }
}
</script>